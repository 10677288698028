<template>
  <b-container>
    <b-row>
      <b-col>
        <b-table small striped hover caption-top responsive
                 :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                 :items="items" :fields="fields">
          <template #cell(name)="{item}">
            <img :src="'/api/data/games-logos/' + item.image"
                 :v-if="item.image" :alt="item.name" width="50px"
                 class="rounded float-left mr-2"/>
            {{ item.name }}<br/>
            <span class="small" v-for="(u,ui) in item.urls" :key="ui">
                <a :href="u" target="_blank" class="mr-1">{{ getUrlName(u) }}</a>
               <span v-if="ui != item.urls.length - 1"> | </span>
            </span>
          </template>
          <template #cell(latestCheck)="{item}">
            <p class="text-muted small">
              {{ item.latestCheckFromNow }}<br/>
              {{ item.latestCheckText }}
            </p>
          </template>
          <template #cell(latestGame)="{item}">
            <p class="text-muted small">
              {{ item.latestGameFromNow }}<br/>
              {{ item.latestGameText }}
            </p>
          </template>
          <template #cell(priority)="{item}">
            <p class="text-muted small">{{ item.priority }}</p>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import UrlUtils from '../utils/url-utils';
import moment from "moment";

export default {
  data: function () {
    return {
      items: [],
      sortBy: 'priority',
      sortDesc: false,
      fields: [
        {
          key: 'name',
          label: 'Базовая информация',
          sortable: true
        },
        {
          key: 'latestCheck',
          label: 'Последняя проверка',
          sortable: true,
          thStyle: 'width: 140px;'
        },
        {
          key: 'latestGame',
          label: 'Последняя игра',
          sortable: true,
          thStyle: 'width: 140px;'
        },
        {
          key: 'priority',
          label: 'Пр',
          sortable: true,
          thStyle: 'width: 30px;'
        }
      ]
    }
  },
  created() {
    let url = "/api/platforms.php";
    const promises = [url].map(url => fetch(url).then(r => r.json()));
    return Promise.all(promises).then(r => {
      r[0].forEach(i => {
        this.prepareItem(i);
        this.items.push(i);
      });
    });
  },
  methods: {
    prepareItem(i) {
      i.urls = i.urls || [];
      i._cellVariants = {};
      if (i.latestGame) {
        this.prepareLatestGame(i);
      }
      if (i.latestCheck) {
        this.prepareLatestCheck(i);
      }
    },
    prepareLatestGame(i) {
      const d = moment(i.latestGame);
      i.latestGame = new Date(i.latestGame).getTime();
      i.latestGameText = d.format('D MMMM HH:mm, dd');
      i.latestGameFromNow = d.fromNow();
      const h = moment().diff(d, 'hours');
      const limit1 = i.archived ? 72 : 12;
      const limit2 = i.archived ? 168 : 24;
      if (h > limit1) {
        i._cellVariants.latestGame = h < limit2 ? 'warning' : 'danger';
      }
    },
    prepareLatestCheck(i) {
      const d = moment(i.latestCheck);
      i.latestCheck = new Date(i.latestCheck).getTime();
      i.latestCheckText = d.format('D MMMM HH:mm, dd');
      i.latestCheckFromNow = d.fromNow();
      const h = moment().diff(d, 'hours');
      const limit1 = i.archived ? 168 : 6;
      const limit2 = i.archived ? 336 : 24;
      if (h > limit1) {
        i._cellVariants.latestCheck = h < limit2 ? 'warning' : 'danger';
      }
    },
    getUrlName: function (url) {
      return UrlUtils.getUrlName(url);
    }
  }
}
</script>
